<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import store from "@/state/store";
export default {
  beforeRouteEnter(to, from, next) {
    const userType = store.getters["auth/getUser"].user_type;
    if (userType === 2 || userType === 3) {
      next();
    } else {
      alert("You are not allowed to access this page. You will be redirected.");
      next("/");
    }
  },
  page: {
    title: "Leave Planner",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  mounted() {
    this.fetchFYs();
  },
  data() {
    return {
      title: "Leave Planner",
      items: [
        {
          text: "IHUMANE",
        },
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Leave Planner",
          active: true,
        },
      ],
      fys: [{ value: null, text: "Select FY" }],
      fy: null,
    };
  },
  methods: {
    fetchFYs() {
      this.fys = [{ value: null, text: "Select FY" }];
      for (let i = 0; i < 20; i++) {
        const currentYear = new Date().getFullYear() + i;
        this.fys.push({ value: `FY${currentYear}`, text: `FY${currentYear}` });
      }
    },
    generate() {
      if (!this.fy) {
        this.apiFormHandler("Leave Planner");
      } else {
        this.$router.push({
          name: "view-leave-planner",
          params: { fyYear: this.fy },
        });
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <div class="p-3 bg-light mb-4 d-flex justify-content-between">
              <h5 class="font-size-14 mb-0">Select FY</h5>
            </div>
            <form @submit.prevent="generate">
              <div class="form-group">
                <label for="">Select FY</label>
                <b-form-select v-model="fy" :options="fys" />
              </div>
              <b-button
                v-if="!submitting"
                class="btn btn-success btn-block mt-4"
                type="submit"
              >
                Submit
              </b-button>
              <b-button
                v-else
                disabled
                class="btn btn-success btn-block mt-4"
                type="submit"
              >
                Submitting...
              </b-button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
